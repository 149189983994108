import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerAssignInstaller from "../Pages/Customer/CustomerAssignInstaller";
import CustomerAssignMeasurer from "../Pages/Customer/CustomerAssignMeasurer";
import CustomerStatusDetail from "../Pages/Customer/CustomerStatusDetail";
// import CustomerSchedule from "../Pages/Customer/CustomerSchedule";
import CustomerViewEstimate from "../Pages/Customer/CustomerViewEstimate";
import CustomerLogin from "../Pages/Auth/CustomerLogin";
import CustomerEnquiryList from "../Pages/Customer/CustomerEnquiryList";
import CustomerSelectOption from "../Pages/Customer/CustomerSelectOption";
import CustomerComplaintCategory from "../Pages/Customer/CustomerComplaintCategory";
import CustomerComplaintForm from "../Pages/Customer/CustomerComplaintForm";
import CustomerFeedback from "../Pages/Customer/CustomerFeedback";
import ProtectedLoginRoute from "../Pages/Auth/ProtectedLoginRoute";
import ProtectedRoute from "../Pages/Auth/ProtectedRoute";
import Error from "../Pages/404/Error";
import OrderBillForm from "../Pages/Customer/OrderBillForm";
import ThanksPage from "../Pages/Customer/ThanksPage";
import PaymentPage from "../Pages/Customer/PaymentPage";
import CustomerReassignMeasurer from "../Pages/Customer/CustomerReassignMeasurer";
import CustomerReassignInstaller from "../Pages/Customer/CustomerReAssignInstaller";

const CommonRoutes = () => {
    return (<>
        <BrowserRouter
        // basename={process.env.PUBLIC_URL}
        >
            <Routes>
                {/* <Route path="customer-login/:url" element={<CustomerLogin />} /> */}
                {/* <Route path="/" element={< } /> */}
                {/* <Route path="*" element={<Error />} /> */}
                <Route path="customer-assign-Installer/:url" element={<CustomerAssignInstaller />} />
                <Route path="customer-assign-measurer/:url" element={<CustomerAssignMeasurer />} />
                <Route path="customer-re-assign-measurer/:url" element={<CustomerReassignMeasurer />} />
                <Route path="customer-re-assign-installer/:url" element={<CustomerReassignInstaller />} />
                <Route path="customer-status-detail/:url" element={<CustomerStatusDetail />} />
                {/* <Route path="customer-schedule/:url" element={<CustomerSchedule />} /> */}
                <Route path="/customer-view-estimate" element={<CustomerViewEstimate />} />
                <Route path="customer-enquiries/:url" element={<CustomerEnquiryList />} />
                <Route path="customer-service/:url" element={<CustomerSelectOption />} />
                <Route path="customer-complaint-category" element={<CustomerComplaintCategory />} />
                <Route path="customer-complaint-form" element={<CustomerComplaintForm />} />
                <Route path="order-bill-form" element={<OrderBillForm />} />
                <Route path="customer-feedback/:url" element={<CustomerFeedback />} />
                <Route path="payment-gateway" element={<PaymentPage />} />
                <Route path="/Thank-you" element={<ThanksPage />} />
                <Route path="*" element={<Error />} />
            </Routes>
        </BrowserRouter>
    </>
    )
}
export default CommonRoutes;