import { useState } from "react";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";
import { PostData } from "../../ApiHelper/ApiHelper";
import { toast } from "react-toastify";

const OrderBillForm = () => {
  const [isLoading, setIsLoading] = useState();
  const [imageUrl, setImageUrl] = useState({
    image_url: "",
    image_view: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", imageUrl?.image_url);
    formData.append("orderId", data?.order_id);
    formData.append("remark", data?.remark);
    setIsLoading(true);
    console.log("errorrs", errors);
    // let loginData = {
    //   ...data,
    //   file: imageUrl?.image_url
    // };
    // console.log(loginData);

    PostData(`order/submit-order-bill`, formData).then((response) => {
      if (response.status === true) {
        // Cookies.set("f&fMobileNo", data?.mobile);
        toast.success(response?.message);
        reset();
        setImageUrl({
          image_url: "",
          image_view: "",
        })
        // navigation("/customer-Enquiries", { state: { data: response.data } });
        // console.log(response);
        // Cookies.set("FandFToken", response.data.accessToken);
        // Cookies.set("userType", response.data.type);
        // Cookies.set("userID", response.data.id);
        // dispatch(setLoggedInUserDetails(response.data));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("loginnnnn.", response);
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      }
    });
  };
  const imageUploadHandler = (event) => {
    setImageUrl({
      image_url: event.target.files[0],
      image_view: URL.createObjectURL(event.target.files[0]),
    })
  }
  return (<>
    <div className="authincation h-100">
      <div className="container h-100vh">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <a
                        href="#"
                        className="d-inline-block mb-5"
                      >
                        <img
                          src="../images/loginLogo.png"
                          // src={`../images/loginLogo.png`}
                          style={{ width: "200px" }}
                          alt="test"
                        />
                      </a>
                    </div>
                    <h4 className="text-center mb-4">Create Order Bill</h4>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="mb-3">
                        <label className="mb-1">
                          <strong>Order ID</strong>
                        </label>
                        <input
                          {...register("order_id", {
                            required: true,
                            maxLength: 80,
                          })}
                          // defaultValue={url}
                          type="text"
                          className="form-control"
                          placeholder="Enter your Oder id"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="mb-1">
                          <strong>Remark</strong>
                        </label>
                        <textarea
                          {...register("remark", {
                            required: true,
                            // maxLength: 80,
                          })}
                          // defaultValue={url}
                          // type="number"
                          className="form-control"
                          placeholder="Enter your remark"
                        />
                      </div>
                      <div className="mb-3">
                        {imageUrl?.image_view ?
                          <div className="d-flex  justify-content-center position-relative">
                            <div className="position-relative">
                              <span className="position-absolute top-0 end-0">
                                <i className="fas fa-times"
                                  onClick={() => {
                                    setImageUrl({
                                      image_url: "",
                                      image_view: "",
                                    })
                                  }}
                                ></i>
                              </span>
                              <img src={imageUrl?.image_view}
                                height={200} width={200}
                              />
                            </div>


                          </div> :

                          <>
                            <label className="mb-1 d-flex align-items-center flex-column" for="upload-image">
                              <img src="/images\pngtree-file-upload-icon-image_1344393.jpg"
                                height={200} width={200}
                              />
                              <p>Upload Image</p>
                            </label>
                            <input className="d-none"
                              onChange={imageUploadHandler}
                              type="file" accept="image/png, image/gif, image/jpeg" id="upload-image" />
                          </>}
                      </div>

                      <div className="text-center">
                        <button
                          disabled={isLoading}
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          {isLoading === true ? (
                            <Spinner size="small" />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default OrderBillForm;