export default function PaymentPage() {

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("Submit Data:", submitData);

    // PostData(`customer/add-feedback`, submitData).then((response) => {
    //   if (response.status === true) {
    //     toast.success("Feedback sent successfully");
    //     navigate("/Thank-you");
    //   } else {
    //     toast.error(response.data.message);
    //   }
    // });
  };
  return (<div className="authincation h-100">
    <div className="container h-100vh">
      <div className="row justify-content-center h-100 align-items-center">
        <div className="col-xl-12">
          <div className="authincation-content">
            <div className="row no-gutters">
              <div className="col-xl-12 px-4 py-2">
                <div className="card-header">
                  <h4>Please enter your Mobile Number</h4>
                  <span
                    onClick={() => {
                      // navigate(-1);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fas fa-arrow-left" style={{ fontSize: "20px" }}></i>
                  </span>
                </div>
                <div className="basic-form mx-3">
                  <form>
                    <div class="col-lg-12 my-1">
                      <label class="me-sm-2">Enter Mobile Number</label>
                      <input type="number"
                      // min={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0]}
                      // class="form-control" name="date"
                      // {...register(`date`, {
                      //   required: true,
                      //   maxLength: 80,
                      // })}
                      />
                    </div>
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)
}