// import { useEffect, useState } from "react";
// import { GetDataWithToken } from "../../ApiHelper/ApiHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { GetData, PostDataWithToken } from "../../ApiHelper/ApiHelper";
import { useEffect, useState } from "react";
import FormLoader from "../../Components/Common/FormLoader";

const CustomerStatusDetail = () => {
  const navigate = useNavigate();
  const [EnquiryDetials, setEnquiryDetials] = useState({});
  const { url } = useParams();
  const [formStates, setFormStates] = useState({
    loader: true,
    showForm: false,
  });

  useEffect(() => {
    GetData(`sales/get-enquiry/${url}`).then(
      (response) => {
        updateStateHandler("loader", false);
        if (response.status === true) {
          if (response?.data?.orders?.length > 0) {

            let text = "Order created";
            const index = response?.data?.enquirystatuses?.findIndex((item) =>
              item?.remark?.includes(text)
            );

            let statusArray = [];
            if (index !== -1) {
              updateStateHandler("showForm", true);
              statusArray = response.data.enquirystatuses.slice(index);
            }
            setEnquiryDetials({
              ...response?.data,
              enquirystatuses: statusArray
            });

          }
        }
      }
    );
  }, [])

  const updateStateHandler = (key, value) => {
    setFormStates((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  return (
    <div className="authincation h-100">
      <div className="container h-100vh">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-6">
            {formStates?.loader ? <FormLoader /> : <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  {!formStates?.showForm ?
                    <div class="card-header d-flex justify-content-center">
                      <h4>Access Denied !</h4>
                    </div> : <>
                      <div className="card-header">
                        <h4>Status Detail</h4>
                      </div>
                      <div className="row flex-item-center">
                        <div className="col-xl-12">
                          <div className="d-flex row mx-2">
                            <div className="card">
                              <div className="card-body">
                                <h4 className="card-intro-title">Enquiry No.</h4>
                                <div
                                  id="DZ_W_TimeLine"
                                  className="widget-timeline dlab-scroll"
                                >
                                  <ul className="timeline">
                                    {EnquiryDetials?.enquirystatuses &&
                                      EnquiryDetials?.enquirystatuses?.map(
                                        (item, index) => {
                                          return (
                                            <li>
                                              <div className="timeline-badge primary" />
                                              <a
                                                className="timeline-panel text-muted"

                                              >
                                                <span className="text-white">
                                                  {moment(item.createdAt).format("LLL")}
                                                </span>
                                                <h6 className="mb-0 text-white">
                                                  {item.remark} #{item.enquiryId}

                                                </h6>
                                              </a>
                                            </li>
                                          );
                                        }
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </>}



                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerStatusDetail;