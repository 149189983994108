import logo from './logo.svg';
import './App.css';

import { useEffect, useState } from "react";
import CommonRoutes from './Routes/CommonRoutes';
import Cookies from "js-cookie";

function App() {


  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "js/custom.min.js";

  //   script.async = true;
  //   setTimeout(() => {
  //     script.onload = () => scriptLoaded();
  //   }, 1500);

  //   document.body.appendChild(script);
  // }, []);

  // const scriptLoaded = () => {
  //   window.Travl();
  // };

  return (
    <>
      <CommonRoutes />
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
