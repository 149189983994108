export default function FormLoader() {
  return (<>
    {/* <!-- code here --> */}
    <div class="card">
      <div class="card-img skeleton">
        {/* <!-- waiting for img to load from javascript --> */}
      </div>
      <div class="card-body">
        <h2 class="card-title skeleton">
          {/* <!-- wating for title to load from javascript --> */}
        </h2>
        <p class="card-intro skeleton">
          {/* <!-- waiting for intro to load from Javascript --> */}
        </p>
        <div className="d-flex justify-content-center">
          <div class="card-button skeleton">
            {/* <!-- waiting for intro to load from Javascript --> */}
          </div>
        </div>
      </div>
    </div>
  </>)
}