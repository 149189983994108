import { useEffect, useState } from "react";

// import { GetData, GetDataWithToken, PostData } from "../../ApiHelper/ApiHelper";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetData, PostDataWithToken } from "../../ApiHelper/ApiHelper";
import Cookies
  from "js-cookie";
import moment from "moment";
import FormLoader from "../../Components/Common/FormLoader";
const CustomerAssignInstaller = () => {
  const { url } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [scheduleMessage, setScheduleMessage] = useState("");
  const [EnquiryDetials, setEnquiryDetials] = useState({});
  const [AllTimeSlot, setAllTimeSlot] = useState([]);
  const [formStates, setFormStates] = useState({
    loader: true,
    showForm: false,
  });
  // const [callApi, setCallApi] = useState(true);
  // const [callApi2, setCallApi2] = useState(false);
  const [timeSlotValue, setTimeSlotValue] = useState();

  const [selectedInstaller, setSelectedInstaller] = useState({
    name: "",
    id: "",
  })
  // const [installerList, setInstallerList] = useState([]);
  // const [wareHouseList, setWareHouseList] = useState([]);
  const timeSlotChangeHandler = (e) => {
    setTimeSlotValue(e.target.value);
    // setCallApi2(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();




  useEffect(() => {
    GetData(`sales/get-enquiry/${url}`).then(
      (response) => {
        updateStateHandler("loader", false);
        if (response.status === true) {
          if (response?.data?.status == "qc-complete") {
            updateStateHandler("showForm", true);
          }
          setEnquiryDetials(response?.data);
          getUnassignedInstaller(response?.data?.category, response?.data?.outlet?.id);
          // GetData(`installer/unassign-installer-slot?warehouseId=${response?.data?.outlet?.id}`).then(response => {
          //   setScheduleList(response.data);
          //   Cookies.set("FandFInstallerToken", response?.data?.user?.accessToken)
          //   // setCallApi(false)
          // })
        }
      }
    );
  }, [])

  const updateStateHandler = (key, value) => {
    setFormStates((prev) => ({
      ...prev,
      [key]: value
    }));
  }
  const getUnassignedInstaller = (category, storeId) => {
    const installerData = {
      categories: category,
      storeId: storeId,
      enquiryId: url
    };
    PostDataWithToken("installer/get-unassign-installer", installerData).then((response) => {
      if (response?.status) {
        setSelectedInstaller({
          name: `${response?.data?.[0]?.firstName} ${response?.data?.[0]?.lastName}`,
          id: response?.data?.[0]?.id,
        });
      }
    })
  }

  const dateChangeHandler = (event) => {
    let date = moment(event?.target?.value).format('YYYY-MM-DD');
    let usedCategory = "";
    const fabricCategory = ["Curtains", "sheers", "Rods/tracks", "Blinds"];
    if (EnquiryDetials?.category.every(category => fabricCategory.includes(category))) {
      usedCategory = "curtain";
    }
    GetInstallerSchedule(date, selectedInstaller?.id, usedCategory)
  }

  const GetInstallerSchedule = (date, installer, category) => {
    GetData(`installer/unassign-installer-slot?date=${date}&userid=${installer}${category ? `&category=${category}` : ""}`).then(response => {
      let newSchedules = [];
      if (moment(date).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY')) {
        for (let i = 0; i < response?.data?.schedules?.length; i++) {
          let startTime = response?.data?.schedules?.[i]?.start_time;
          let startTimeMoment = moment(startTime, "HH:mm:ss");
          let currentTime = new Date();
          let currentHour = currentTime.getHours();
          let currentMinute = currentTime.getMinutes();
          // Create a moment for the current time using the current hour and minute
          let currentTimeMoment = moment(`${currentHour}:${currentMinute}:00`, "HH:mm:ss");
          // Calculate the difference in duration between current time and start time
          let duration = moment.duration(startTimeMoment.diff(currentTimeMoment));
          // Convert the duration to minutes
          let minutes = duration.asMinutes();
          // Log the result in minutes
          if (+minutes > 40) {
            newSchedules.push(response?.data?.schedules?.[i]);
          }
          console.log(`Start Time: ${startTime}, Difference: ${minutes} minutes`);
        }
        setAllTimeSlot(newSchedules);
      } else {
        setAllTimeSlot(response?.data?.schedules);
      }

      if (response.data?.schedules?.length === 0) {
        setScheduleMessage("Sorry no schedules on Sunday");
      } else {
        setScheduleMessage("");
      }
    })
  }

  const onSubmit = (data) => {
    // console.log("submit dataaaa...", data);
    const submitData = {
      customerId: EnquiryDetials?.customer?.id,
      scheduleId: timeSlotValue,
      installerId: selectedInstaller?.id,
      warehouseId: EnquiryDetials?.outlet?.id,
      date: data?.date,
      enquiryId: EnquiryDetials?.id,
    }

    PostDataWithToken(`installer/assign-installer`, submitData).then(response => {
      if (response?.status === true) {
        toast.success("Installation slot booked");
        // navigate(-1);
        navigate("/thank-you")
        Cookies.remove("FandFInstallerToken");
      } else {
        toast.error(response?.data?.message);
      }
    })

  }

  return (
    <div className="authincation h-100">
      <div className="container h-100vh">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-6">
            {formStates?.loader ? <FormLoader /> : <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  {!formStates?.showForm ?
                    <div class="card-header d-flex justify-content-center">
                      <h4>Access Denied !</h4>
                    </div> : <>
                      <div className="card-header">
                        <h4>Schedule a task</h4>
                      </div>
                      <div className="container-fluid">
                        <div className="row flex-item-center">
                          <div className="col-xl-12">
                            <div class="basic-form">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div class="row align-items-center">

                                  <div class="col-lg-12 my-1">
                                    <label class="me-sm-2">Select Date For Installation</label>
                                    <input type="date"
                                      required
                                      min={new Date(new Date().setDate(new Date().getDate())).toISOString().split('T')[0]}
                                      class="form-control" name="date"
                                      {...register(`date`, {
                                        required: true,
                                        maxLength: 80,
                                      })}
                                      onChange={dateChangeHandler}
                                    />
                                  </div>

                                  <div class="col-lg-12 my-1">
                                    <label class="me-sm-2">Select Time Slot For Your Installer</label>
                                    <select class="me-sm-2  form-control"
                                      onChange={timeSlotChangeHandler}
                                      required
                                    >
                                      <option>Choose...</option>
                                      {AllTimeSlot?.map((data) => <option value={data?.id}>{data?.start_time}-{data?.end_time}</option>)}
                                    </select>
                                  </div>
                                  <p className="text-danger">{scheduleMessage}</p>
                                  {/* <div class="col-lg-12 my-1">
                                                                <label class="me-sm-2">Select Installer</label>
                                                                <select class="me-sm-2  form-control"  {...register(`installer`, {
                                                                    required: true,
                                                                    maxLength: 80,
                                                                })}>
                                                                    <option>Choose...</option>
                                                                    {installerList.map((data) => <option value={data?.id}>{data?.firstName} {data?.lastName}</option>)}
                                                                </select>
                                                            </div> */}
                                </div>
                                <button class="btn btn-primary my-4">submit</button></form></div>
                          </div>
                        </div>
                      </div>
                    </>}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerAssignInstaller;