export default function ThanksPage() {
  return (<>
    <div class="authincation h-100">
      <div class="container h-100vh">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-xl-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="card-header d-flex justify-content-center">
                    <h4>Thank You !</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}