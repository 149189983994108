import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetData } from "../../ApiHelper/ApiHelper";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import moment from "moment/moment";

const CustomerEnquiryList = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  // const { url1, url2 } = useParams();
  const { url } = useParams();
  // console.log("uiuiuiuiui...", url1, " nmnm ", url2);
  const location = useLocation();
  const navigate = useNavigate();
  const [enquiries, setEnquiries] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const enquiries = location?.state?.data;

  useEffect(() => {
    setIsLoading(true);
    GetData(`customer/get-my-enquiry/${url}`).then((response) => {
      if (response.status === true) {
        setEnquiries(response.data);
        // Cookies.set("f&fMobileNo", data?.mobile);
        // toast.success("Logged in successfully");
        // navigation("/customer-Enquiries", { state: { data: response.data } });
        // console.log(response);
        // Cookies.set("FandFToken", response.data.accessToken);
        // Cookies.set("userType", response.data.type);
        // Cookies.set("userID", response.data.id);
        // dispatch(setLoggedInUserDetails(response.data));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("loginnnnn.", response);
        // toast.error(response?.data?.message, {
        //   position: toast.POSITION.TOP_LEFT,
        // });
      }
    });

  }, [])

  return (
    <>
      <div className="authincation h-100">
        <div className="container h-100vh">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-12">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <div class="alert bg-secondary mx-1 mt-2 mb-2"><div class="row">
                      <div class="col-xl-10">
                        <h3 class="text-white">Hello {enquiries?.firstName} {enquiries?.lastName} Choose a enquiry</h3>
                      </div>
                    </div>
                    </div>
                    <div className="table-responsive">
                      {isLoading && <div className="text-center"><Spinner /> <h4>Loading...</h4></div>}
                      {enquiries?.enquiries.length === 0 && !isLoading &&
                        <div>
                          <h3 style={{ textAlign: "center" }}>No enquiries found</h3>
                        </div>}
                      {enquiries?.enquiries.length > 0 && !isLoading && <table
                        id="example4"
                        className="table card-table display mb-4 shadow-hover table-responsive-xs"
                      // style={{ minWidth: "845px" }}
                      >
                        <thead>
                          <tr>
                            <th>Enquiry No.</th>
                            <th>Status</th>
                            <th> Store Name</th>
                            <th>IC Name</th>
                            <th>Categories</th>
                            <th>Date</th>
                            {/* <th>Category</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enquiries?.enquiries?.map((data) => <tr>
                            <td>{data?.enquiryId}</td>
                            <td>{data?.status}</td>
                            <td>
                              {data?.outlet?.firstName}
                            </td>
                            <td>
                              {data?.user?.firstName} {data?.user?.lastName}
                            </td>
                            <td>
                              {data?.products?.length > 0 && data?.products.map((data) => <span>{data} ,</span>)}
                            </td>
                            <td>
                              {moment(data?.createdAt).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              <button className="btn btn-primary"
                                onClick={() => navigate("/customer-service", {
                                  state: {
                                    orderId: data,
                                  }
                                })}
                              >
                                View
                              </button>
                            </td>
                          </tr>)}
                        </tbody>
                      </table>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CustomerEnquiryList;